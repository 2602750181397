import React, { Suspense, Fragment, lazy, useLocation } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import ClientLayout from './layouts/ClientLayout';
import AdminLayout from './layouts/AdminLayout';
import OpsSupLayout from './layouts/OpsSupLayout';
import CstSupLayout from './layouts/CstSupLayout';
import OpsLayout from './layouts/OpsLayout';
import CstLayout from './layouts/CstLayout';
import SalesLayout from './layouts/SalesLayout';
import QcLayout from './layouts/QcLayout';
import ClientEmployeeLayout from './layouts/ClientEmployeeLayout';
import DigitalAddressLayout from './layouts/DigitalAddressLayout';
import AccountsLayout from './layouts/AccountsLayout';

// import GuestGuard from './components/Auth/GuestGuard';
// import AuthGuard from './components/Auth/AuthGuard';

import { BASE_URL } from './config/constant';
import { SessionStorage } from './util/SessionStorage';
import { PortalUserRoles } from './helper/constants';

let layout;

switch (sessionStorage.getItem('user_category')) {
  case PortalUserRoles.Admin:
    layout = AdminLayout;
    break;
  case PortalUserRoles.Client:
    if (SessionStorage.getItem('user_type') === PortalUserRoles.ClientUserTypes.HR) {
      layout = ClientLayout;
      break;
    } else {
      layout = ClientEmployeeLayout;
      break;
    }
  case PortalUserRoles.OpsSupervisor:
    layout = OpsSupLayout;
    break;
  case PortalUserRoles.OpsTeam:
    layout = OpsLayout;
    break;
  case PortalUserRoles.Sales:
    layout = SalesLayout;
    break;
  case PortalUserRoles.CstSupervisor:
    layout = CstSupLayout;
    break;
  case PortalUserRoles.CstUser:
    layout = CstLayout;
    break;
  case PortalUserRoles.QualityControl:
    layout = QcLayout;
    break;
  case PortalUserRoles.DigitalAddressVerification:
    layout = DigitalAddressLayout;
    break;
  case PortalUserRoles.Accounts:
    layout = AccountsLayout;
    break;
  default:
    layout = DigitalAddressLayout;
    break;
}

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    // guard: GuestGuard,
    path: '/',
    component: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/auth/signin-1',
    component: lazy(() => import('./views/auth/signin/SignIn1'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/auth/employeeLogin',
    component: lazy(() => import('./views/auth/signin/EmployeeSignIn'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/NotFound404'))
  },
  {
    exact: true,
    path: '/maintenance/coming-soon',
    component: lazy(() => import('./views/maintenance/ComingSoon'))
  },
  {
    exact: true,
    path: '/maintenance/error',
    component: lazy(() => import('./views/maintenance/Error'))
  },
  {
    exact: true,
    path: '/maintenance/offline-ui',
    component: lazy(() => import('./views/maintenance/OfflineUI'))
  },
  {
    exact: true,
    path: '/auth/signup-1',
    component: lazy(() => import('./views/auth/signup/SignUp1'))
  },
  {
    exact: true,
    path: '/auth/signup-2',
    component: lazy(() => import('./views/auth/signup/SignUp2'))
  },
  {
    exact: true,
    path: '/auth/signin-2',
    component: lazy(() => import('./views/auth/signin/SignIn2'))
  },
  {
    exact: true,
    path: '/auth/reset-password-1',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword1'))
  },
  {
    exact: true,
    path: '/auth/reset-password-2',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword2'))
  },
  {
    exact: true,
    path: '/auth/profile-settings',
    component: lazy(() => import('./views/auth/ProfileSettings'))
  },
  {
    exact: true,
    path: '/auth/tabs-auth',
    component: lazy(() => import('./views/auth/TabsAuth'))
  },
  {
    path: '*',
    layout: layout,
    // guard: AuthGuard,
    routes: [
      // -------------------------------

      {
        exact: true,
        path: '/client-portal/ca-dashboard',
        component: lazy(() => import('./views/panels/client-portal/Dashboard'))
      },
      {
        exact: true,
        path: '/client-portal/ca-casedetails/:user_client_id/:case_status',
        component: lazy(() => import('./views/panels/client-portal/CaseDetails'))
      },
      {
        exact: true,
        path: '/client-portal/ca-upload',
        component: lazy(() => import('./views/panels/client-portal/Uploadcase'))
      },
      {
        exact: true,
        path: '/client-portal/ca-addbgv',
        component: lazy(() => import('./views/panels/client-portal/Addbgv'))
      },
      {
        exact: true,
        path: '/client-portal/client-editbgv/:case_id/:user_client_id',
        component: lazy(() => import('./views/panels/client-portal/Editbgv'))
      },
      {
        exact: true,
        path: '/client-portal/ca-userdetails',
        component: lazy(() => import('./views/panels/client-portal/UserDetails'))
      },

      // -------------------------------

      // -------------  Employee Path Start ------------------

      {
        exact: true,
        path: '/client-employee-portal/ce-addbgv',
        component: lazy(() => import('./views/panels/client-employee-portal/Addbgv'))
      },
      {
        exact: true,
        path: '/client-employee-portal/client-employee-editbgv/:case_id/:user_client_id',
        component: lazy(() => import('./views/panels/client-employee-portal/Editbgv'))
      },
      {
        exact: true,
        path: '/auth/change-password',
        component: lazy(() => import('./views/auth/ChangePassword'))
      },
      // -------------  Employee Path End ------------------

      // -------------  Admin Path Start------------------

      {
        exact: true,
        path: '/admin-portal/admin-dashboard',
        component: lazy(() => import('./views/panels/admin-portal/Dashboard'))
      },
      {
        exact: true,
        path: '/admin-portal/admin-clientdetails',
        component: lazy(() => import('./views/panels/admin-portal/ClientDetails'))
      },
      {
        exact: true,
        path: '/admin-portal/admin-userdetails',
        component: lazy(() => import('./views/panels/admin-portal/UserDetails'))
      },
      {
        exact: true,
        path: '/admin-portal/admin-vendordetails',
        component: lazy(() => import('./views/common-ui-components/tables/VendorTableView.js'))
      },
      {
        exact: true,
        path: '/admin-portal/admin-casedetails/:user_client_id/:case_status',
        component: lazy(() => import('./views/panels/admin-portal/CaseDetails'))
      },
      {
        exact: true,
        path: '/admin-portal/admin-dashboard-casedetails/:client_id/:case_status',
        component: lazy(() => import('./views/panels/admin-portal/DashboardCaseDetails'))
      },
      {
        exact: true,
        path: '/admin-portal/admin-editbgv/:case_id/:user_client_id',
        component: lazy(() => import('./views/panels/admin-portal/Editbgv.js'))
      },
      {
        exact: true,
        path: '/admin-portal/admin-clientpricing/:client_id',
        component: lazy(() => import('./views/panels/admin-portal/ClientPricing'))
      },

      // -------------  Admin Path End ------------------

      // -------------  Ops Sup Path Start ------------------

      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-dashboard',
        component: lazy(() => import('./views/panels/operations-supervisor-portal/OpsSupDashboard'))
      },
      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-clientdetails',
        component: lazy(() => import('./views/panels/operations-supervisor-portal/OpsSupClientDetails'))
      },
      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-casedetails/:user_client_id/:case_status',
        component: lazy(() => import('./views/panels/operations-supervisor-portal/OpsSupCaseDetails'))
      },
      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-dashboard-casedetails/:client_id/:case_status',
        component: lazy(() => import('./views/panels/operations-supervisor-portal/OpsSupDashboardCaseDetails'))
      },
      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-verifycase/:case_id/:user_client_id',
        component: lazy(() => import('./views/panels/operations-supervisor-portal/OpsSupVerifyCase'))
      },
      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-detailedView/:case_id/:user_client_id/:clientName',
        component: lazy(() => import('./views/panels/operations-supervisor-portal/OpsSupDetailedView.js'))
      },
      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-userdetails',
        component: lazy(() => import('./views/panels/operations-supervisor-portal/OpsSupUserDetails'))
      },
      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-addbgv/:user_client_id',
        component: lazy(() => import('./views/panels/operations-supervisor-portal/OpsSupAddbgv'))
      },
      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-addbgv/:user_client_id/demoemp',
        component: lazy(() => import('./views/common-ui-components/sow/scopes/Employment'))
      },
      {
        exact: true,
        path: '/operations-supervisor-portal/ops-sup-upload/:user_client_id',
        component: lazy(() => import('./views/panels/client-portal/Uploadcase'))
      },

      // -------------  Ops Sup Path End ------------------

      // -------------  Ops Path Start ------------------

      {
        exact: true,
        path: '/operations-portal/ops-dashboard',
        component: lazy(() => import('./views/panels/operations-portal/OpsDashboard'))
      },
      {
        exact: true,
        path: '/operations-portal/ops-casedetails/:case_status',
        component: lazy(() => import('./views/panels/operations-portal/OpsCaseDetails'))
      },
      {
        exact: true,
        path: '/operations-portal/ops-casedetails/:client_id/:case_status',
        component: lazy(() => import('./views/panels/operations-portal/OpsCaseDetails'))
      },
      {
        exact: true,
        path: '/operations-portal/ops-verifycase/:case_id/:user_client_id',
        component: lazy(() => import('./views/panels/operations-portal/OpsVerifyCase'))
      },

      // -------------  Ops Path End ------------------

      // -------------  Sales Path Start ------------------

      {
        exact: true,
        path: '/sales-portal/sales-clientdetails',
        component: lazy(() => import('./views/panels/sales-portal/SalesClientDetails'))
      },

      // -------------  Sales Path End ------------------

      // -------------  CST Sup Path Start ------------------

      {
        exact: true,
        path: '/cst-supervisor-portal/cst-sup-dashboard',
        component: lazy(() => import('./views/panels/cst-supervisor-portal/CstSupDashboard'))
      },
      {
        exact: true,
        path: '/cst-supervisor-portal/cst-sup-clientdetails',
        component: lazy(() => import('./views/panels/cst-supervisor-portal/CstSupClientDetails'))
      },
      {
        exact: true,
        path: '/cst-supervisor-portal/cst-sup-userdetails',
        component: lazy(() => import('./views/panels/cst-supervisor-portal/CstSupUserDetails'))
      },
      {
        exact: true,
        path: '/cst-supervisor-portal/cst-sup-casedetails/:user_client_id/:case_status',
        component: lazy(() => import('./views/panels/cst-supervisor-portal/CstSupCaseDetails'))
      },
      {
        exact: true,
        path: '/cst-supervisor-portal/cst-sup-dashboard-casedetails/:client_id/:case_status',
        component: lazy(() => import('./views/panels/cst-supervisor-portal/CstSupDashboardCaseDetails'))
      },
      {
        exact: true,
        path: '/cst-supervisor-portal/cst-sup-verifycase/:case_id/:user_client_id',
        component: lazy(() => import('./views/panels/cst-supervisor-portal/CstSupVerifyCase'))
      },
      {
        exact: true,
        path: '/cst-supervisor-portal/cst-sup-addbgv/:user_client_id',
        component: lazy(() => import('./views/panels/cst-supervisor-portal/CstSupAddbgv'))
      },
      // {    not in use
      //   exact: true,
      //   path: '/cst-supervisor-portal/cst-sup-upload/:user_client_id',
      //   component: lazy(() => import('./views/panels/cst-supervisor-portal/CstSupUploadcase'))
      // },

      // -------------  CST Sup Path End -------------------

      // -------------  CST Path Start ------------------

      {
        exact: true,
        path: '/cst-portal/cst-dashboard',
        component: lazy(() => import('./views/panels/cst-portal/CstDashboard'))
      },
      {
        exact: true,
        path: '/cst-portal/cst-clientdetails',
        component: lazy(() => import('./views/panels/cst-portal/CstClientDetails'))
      },
      {
        exact: true,
        path: '/cst-portal/cst-casedetails/:user_client_id/:case_status',
        component: lazy(() => import('./views/panels/cst-portal/CstCaseDetails'))
      },
      {
        exact: true,
        path: '/cst-portal/cst-dashboard-casedetails/:client_id/:case_status',
        component: lazy(() => import('./views/panels/cst-portal/CstDashboardCaseDetails'))
      },
      {
        exact: true,
        path: '/cst-portal/cst-verifycase/:case_id/:user_client_id',
        component: lazy(() => import('./views/panels/cst-portal/CstVerifyCase'))
      },

      // -------------  CST Path End ------------------

      // -------------  Qc Path Start ------------------

      {
        exact: true,
        path: '/qc-portal/qc-dashboard',
        component: lazy(() => import('./views/panels/qc-portal/QcDashboard'))
      },
      {
        exact: true,
        path: '/qc-portal/qc-clientdetails',
        component: lazy(() => import('./views/panels/qc-portal/QcClientDetails'))
      },
      {
        exact: true,
        path: '/qc-portal/qc-casedetails/:user_client_id/:case_status',
        component: lazy(() => import('./views/panels/qc-portal/QcCaseDetails'))
      },
      {
        exact: true,
        path: '/qc-portal/qc-dashboard-casedetails/:client_id/:case_status',
        component: lazy(() => import('./views/panels/qc-portal/QcDashboardCaseDetails'))
      },
      {
        exact: true,
        path: '/qc-portal/qc-verifycase/:case_id/:user_client_id',
        component: lazy(() => import('./views/panels/qc-portal/QcVerifyCase'))
      },
      // -------------  Qc Path End ------------------

      // -------------  Digital Address Verification Path Start ------------------
      {
        exact: true,
        path: '/digitalAddressVerification/:case_id',
        component: lazy(() => import('./views/panels/digital-address-verification-portal/DigitalAddressVerification'))
      },
      // -------------  Digital Address Verification Path End ------------------

      // -------------  Accounts Path Start ------------------
      {
        exact: true,
        path: '/accounts-portal/generate-invoice',
        component: lazy(() => import('./views/panels/accounts-portal/GenerateInvoice'))
      },
      {
        exact: true,
        path: '/accounts-portal/invoice-overview',
        component: lazy(() => import('./views/panels/accounts-portal/InvoiceOverview'))
      },
      {
        exact: true,
        path: '/accounts-portal/invoice-details/:invoice_overview_id',
        component: lazy(() => import('./views/panels/accounts-portal/InvoiceDetails'))
      },
      // -------------  Accounts Path End ------------------
      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />
      }
    ]
  }
];

export default routes;